import React from 'react'
import { Link } from 'react-router-dom'

import landing_data from './data'

type CardProps = {
  img: string
  name: string
  description: string
  link: string
  lat: number
  lon: number
  isLeft: boolean
}

export const Index: React.FC<CardProps> = ({ img, name, description, link, lat, lon, isLeft }) => {
  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0)
  }

  return (
    <div className={'landing__item'}>
      <Link
        hrefLang="en"
        onClick={handleClick}
        className={!isLeft ? 'landing__item-right' : 'landing__item-left'}
        to={link}
        state={{ lat: lat, lon: lon }}
      >
        <h3>{name}</h3>
        <div className="landing__item-img">
          <img src={img} alt={name} />
        </div>
        <div className="landing__item-text">
          <p>{description}</p>
        </div>
      </Link>
    </div>
  )
}

interface CardInterface {
  selectedCategory: any
}

export const Card: React.FC<CardInterface> = ({ selectedCategory }) => {
  const filteredData = landing_data.filter(
    (obj) =>
      selectedCategory === null ||
      (Array.isArray(selectedCategory) && selectedCategory.includes(obj.type)),
  )
  const sortedData = [...filteredData]

  return (
    <div className="landing__card">
      <div className="container">
        <div className="card_wrapper">
          {sortedData.map((obj, i) => (
            <Index key={i} {...obj} isLeft={i % 2 === 0} />
          ))}
        </div>
      </div>
    </div>
  )
}
