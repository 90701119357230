import '@smastrom/react-rating/style.css'

import { Rating, RoundedStar } from '@smastrom/react-rating'
import cns from 'classnames'
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik'
import { useCallback, useState } from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { postFeedback } from '@/api/feedback.api'
import { useAppDispatch, useAppSelector } from '@/core/store'
import { closeModal } from '@/core/store/ui.store'
import { validEmail } from '@/core/utils/validation'

export interface IFeedbackForm {
  comment: string
  email: string
  latitude: number | null
  longitude: number | null
}

const ratingStyles = {
  itemShapes: RoundedStar,
  activeFillColor: '#F4BB44',
  inactiveFillColor: '#ddd',
  width: 38,
}

export const FeedbackModal = () => {
  const [loading, setLoading] = useState(false)
  const [rating, setRating] = useState(0)
  const { userRealPosition } = useAppSelector((store) => store.uiState)
  const { modal } = useAppSelector((store) => store.uiState)
  const dispatch = useAppDispatch()

  const { t, i18n } = useTranslation('feedback')

  const formInitial = {
    comment: '',
    email: '',
    latitude: userRealPosition?.latitude || null,
    longitude: userRealPosition?.longitude || null,
  }

  const handleValidation = (values: IFeedbackForm) => {
    const errors = {} as { [key: string]: string }
    //TODO: i18n
    if (!values.comment) {
      errors.comment = t('validation.comment')
    } else if (values.comment.length <= 10) {
      errors.comment = t('validation.commentMinLength')
    } else if (values.email && !validEmail(values.email)) {
      errors.email = t('validation.email')
    }

    return errors
  }

  const handleSubmit = useCallback(
    async (values: IFeedbackForm, { resetForm }: FormikHelpers<IFeedbackForm>) => {
      if (loading) return
      ReactGA.event({
        category: 'submit',
        action: 'FeedbackSend',
        label: 'User Send Feedback',
      })
      setLoading(true)
      const res = await postFeedback({
        ...values,
        rating,
        latitude: values.latitude,
        longitude: values.longitude,
      })
      setLoading(false)

      if (res) {
        resetForm()
        toast.success(t('successToast'))
        dispatch(closeModal())
      }
    },
    [loading, rating, i18n.language],
  )

  return (
    <div className={'feedback' + (modal === 'feedback' ? ' active' : '')}>
      <div className="review-modal">
        <button onClick={() => dispatch(closeModal())} className="review-close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 5L5 19"
              stroke="#2B3537"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5 5L19 19"
              stroke="#2B3537"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <h4 className="center-align">{t('title')}</h4>

        <Formik
          initialValues={formInitial}
          validateOnChange={false}
          validate={handleValidation}
          onSubmit={handleSubmit}
        >
          {({ setFieldError }: FormikHelpers<IFeedbackForm>) => (
            <Form className="review-form ui-form">
              <div className="ui-group">
                <div className="review-form__rating">
                  <Rating
                    style={{ maxWidth: 230 }}
                    value={rating}
                    onChange={setRating}
                    itemStyles={ratingStyles}
                  />
                </div>
              </div>

              <Field type="text" name="comment">
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <div className="input-field">
                    <textarea
                      rows={3}
                      className={cns(
                        'materialize-textarea',
                        meta.touched && meta.error && 'invalid',
                      )}
                      id="review_comment"
                      placeholder={t('comment') ?? 'Comment'}
                      value={field.value}
                      onChange={(v) => {
                        setFieldValue(field.name, v.target.value)
                        setFieldError(field.name, '')
                      }}
                    />
                  </div>
                )}
              </Field>

              <Field type="email" name="email">
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <div className="input-field">
                    <input
                      type="email"
                      id="review_email"
                      placeholder={t('email') ?? 'Email'}
                      value={field.value}
                      className={cns(meta.touched && meta.error && 'invalid')}
                      onChange={(v) => {
                        setFieldValue(field.name, v.target.value)
                        setFieldError(field.name, '')
                      }}
                    />
                  </div>
                )}
              </Field>
              <Field type="hidden" name="latitude" />
              <Field type="hidden" name="longitude" />
              <div className="review-form__action">
                <button type="submit" className={'btn'} data-loading={loading}>
                  {t('action.submit')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
