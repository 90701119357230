import 'swiper/css'
import 'swiper/css/navigation'

import { MapDirectionsRenderer } from '@c/Catalog'
import {
  circleOptions,
  containerStyle,
  createCenterControl,
  createNumberedIcon,
  icon_sizes,
  icon_sizes_x,
  mapOptions,
} from '@c/Catalog/Map/mapStyler'
import { GoogleMap, Marker, TransitLayer } from '@react-google-maps/api'
import { t } from 'i18next'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { FaLocationArrow, FaLocationCrosshairs } from 'react-icons/fa6'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import type { Swiper as SwiperType } from 'swiper'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Coordinates, IMapObject, InterestPoint, Language } from '@/core/interface'
import { Excursion } from '@/core/interface/Excursion'
import { ExcursionCategory } from '@/core/interface/ExcursionCategory'
import { useAppDispatch, useAppSelector } from '@/core/store'
import { getPoints } from '@/core/store/catalog.store'
import {
  changeCentering,
  setLocale,
  setLocationManual,
  setMapLoaded,
  setUserLocation,
} from '@/core/store/ui.store'

const RoutesWindow = ({
  active,
  onClose,
  startExcursion,
  markers,
  isExcursionBeingSelected,
}: {
  data: any
  active: boolean
  onClose: any
  startExcursion: any
  closeExcursion: any
  markers: InterestPoint[]
  isExcursionBeingSelected: boolean
  setExcursionBeingSelected: (state: boolean) => void
}) => {
  const { userLocation, userPosition, locale, locationManual, audioPlaying, centeringAllow } =
    useAppSelector((store) => store.uiState)
  const { excursions, excursionCategories } = useAppSelector((store) => store.catalogStore)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { i18n, t } = useTranslation('routes')
  const location = useLocation()

  const [activeCategory, setActiveCategory] = useState<'all' | number>(1)
  const [viewRoute, setViewRoute] = useState<Excursion | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [markDto, setPointerDataset] = useState<IMapObject | null>(null)
  const [currentZoom, setCurrentZoom] = useState<number>(13)
  const [places, setPlaces] = useState<any[]>([])
  const [buyModal, setBuyModal] = useState(false)
  const [thanksModal, setThanksModal] = useState(false)
  const [nextStep, setNextStep] = useState(false)
  //console.log('RoutesWindow isExc', isExcursionBeingSelected, viewRoute)
  //Swiping position to manage arrows and bold elements
  const [currentSlideIndices, setCurrentSlideIndices] = useState<{ [key: string]: number }>({})
  // const [isBeginning, setIsBeginning] = useState(true)
  // const [isEnd, setIsEnd] = useState(false)
  const handleCategoryChange = (categoryId: number) => {
    setActiveCategory(categoryId)
    ReactGA.event({
      category: 'excursion',
      action: 'CategoryChange',
      label: categoryId.toString(),
    })

    // Reset indices for the new set of excursions
    const newExcursions = excursions.filter((exc) => exc.categoryId === categoryId)
    const newIndices = newExcursions.reduce((acc, exc) => {
      acc[exc.id] = 0
      return acc
    }, {} as { [key: string]: number })

    setCurrentSlideIndices(newIndices)
  }

  useEffect(() => {
    setNextStep(nextStep ? true : viewRoute?.ordered ?? false)
  }, [viewRoute])

  // position setter around the positioning
  const [customLatLng, setCustomLatLng] = useState<{
    latitude: number
    longitude: number
  } | null>(null)

  useEffect(() => {
    const screenWidth = window.screen.width
    const zoom = screenWidth < 800 ? 17 : screenWidth < 1200 ? 18 : 19
    if (location.state) {
      const pos = { latitude: location.state.lat, longitude: location.state.lon }
      //console.log('Manual pos', pos)
      location.state = undefined
      setLocationManual(true)
      setCustomLatLng(pos)
      setCurrentZoom(zoom)
    }
  })

  //When several points are located at exactly the same spot,
  //we artificially move subsequent ones a little on the map,
  //so they would not be fully overlapped and become visible
  const adjustCoordinates = (points: any[]) => {
    const seen = new Map()
    return points.map((point, index) => {
      const key = `${point.coordinates.latitude},${point.coordinates.longitude}`
      if (seen.has(key)) {
        const i = 1 + seen.get(key)
        const offset = i * 0.0005 // ~25 meters
        seen.set(key, i)
        return {
          ...point,
          coordinates: {
            latitude: point.coordinates.latitude,
            longitude: point.coordinates.longitude + offset,
          },
        }
      } else {
        seen.set(key, 0)
        return point
      }
    })
  }

  // Callback function to create marker icons
  // Used for both route points and user location
  const createMarkerIcon = (
    window: any,
    index: number,
    categories: string[],
    zoom?: number,
    heading?: number | null,
  ) => {
    if (!zoom) {
      zoom = 13
    }

    // eslint-disable-next-line no-constant-condition
    const size_cat = categories && categories.length > 0 ? categories[0] : '10'

    if (size_cat == 'user-current-location') {
      return heading
        ? {
            path: FaLocationArrow({}).props.children[0].props.d,
            anchor: new window.google.maps.Point(256, 256),
            strokeColor: '#c04b35',
            fillColor: '#CB4335',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.065,
            rotation: -45 + (heading || 0),
            zindex: 0,
          }
        : {
            path: FaLocationCrosshairs({}).props.children[0].props.d,
            anchor: new window.google.maps.Point(256, 256),
            strokeColor: '#c04b35',
            fillColor: '#CB4335',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.065,
            zindex: 0,
          }
    }

    const icon_file = createNumberedIcon(index + 1) //`/img/new/point${index + 1}.png`

    const icon = {
      url: icon_file,
      scaledSize: new window.google.maps.Size(32, 32),
      anchor: new window.google.maps.Point(16, 16), // Center the icon on the marker
      zindex: 1000 - index,
    }

    return icon
  }

  // Provide default values for mapCenter and userLocation
  const defaultCenter = { lat: 41.89021, lng: 12.4923 } // Center of Rome
  const defaultUserLocation = { latitude: 41.89021, longitude: 12.4923 }

  const mapCenter = useMemo(() => {
    if (customLatLng) {
      return {
        lat: customLatLng.latitude,
        lng: customLatLng.longitude,
      }
    }

    if (centeringAllow && userLocation) {
      return {
        lat: userLocation.latitude,
        lng: userLocation.longitude,
      }
    }

    return defaultCenter
  }, [userLocation, customLatLng, centeringAllow])

  // map events
  const onLoad = useCallback(function callback(map: any) {
    dispatch(setMapLoaded(false))
    setMap(map)

    const bounds = new window.google.maps.LatLngBounds()
    markers.forEach((marker) => {
      bounds.extend(
        new google.maps.LatLng(marker.coordinates.latitude, marker.coordinates.longitude),
      )
    })

    // Кнопка сброса
    const centerControlDiv = document.createElement('div')
    centerControlDiv.classList.add('centerControl')
    centerControlDiv.classList.add('hide')
    const centerControl = createCenterControl({ onClickHandler: resetLocation })
    centerControlDiv.appendChild(centerControl)

    map.controls[google.maps.ControlPosition.LEFT_TOP].push(centerControlDiv)

    setMap(map)
    dispatch(setMapLoaded(true))
  }, [])

  const onUnmount = useCallback(function callback(_map: any) {
    setMap(null)
  }, [])

  useEffect(() => {
    if (centeringAllow) {
      const centerBtn = document.querySelector('.centerControl')
      // @ts-ignore
      centerBtn?.classList.add('hide')
    } else {
      const centerBtn = document.querySelector('.centerControl')
      // @ts-ignore
      centerBtn?.classList.remove('hide')
    }
  }, [centeringAllow])

  const handleMarkerClick = useCallback(async (e: any, marker: InterestPoint) => {
    //console.log('handleMarkerClick', marker)
    setPointerDataset({
      xid: marker.id,
      name: marker.title,
      point: {
        lat: marker.coordinates.latitude,
        lon: marker.coordinates.longitude,
      },
      info: {
        descr: marker.description,
      },
      preview: {
        source: marker.image,
      },
      audioFiles: marker.audioFiles,
    })
  }, [])

  const onZoomChanged = useCallback(() => {
    const zoom = map?.getZoom()

    setCurrentZoom(zoom ?? 13)
  }, [map])

  const onDragEnd = useCallback(() => {
    dispatch(changeCentering(false))
  }, [map])

  const fetchPosition = useCallback(
    debounce(async (newLocation: Coordinates) => {
      dispatch(getPoints({ ...newLocation, locale: i18n.language as Language }))
    }, 300),
    [i18n.language],
  )

  const resetLocation = useCallback(() => {
    setCustomLatLng(null)
    dispatch(changeCentering(true))
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let { latitude, longitude } = position.coords
          //TODO: Make it a nicer solution
          if (
            (latitude > 42.05 || latitude < 41.72) &&
            (longitude < 12.146 || longitude > 12.7777)
          ) {
            latitude = 41.89021
            longitude = 12.4923
          }
          dispatch(setUserLocation({ latitude, longitude }))
          dispatch(getPoints({ latitude, longitude, locale: i18n.language as Language }))
          dispatch(setLocationManual(false))
          map?.setCenter({ lat: latitude, lng: longitude })
        },
        (error) => {
          console.error('Error obtaining location:', error)
          //TODO: another hack
          dispatch(setUserLocation({ latitude: 41.89021, longitude: 12.4923 }))
        },
        {
          enableHighAccuracy: false,
          timeout: 1000,
          maximumAge: Infinity,
        },
      )
    } catch {
      console.error('Geo error')
    }
  }, [locale])

  const renderCount = useRef<number>(0)
  useEffect(() => {
    dispatch(setLocale(i18n.language as Language))

    if (renderCount.current >= 1) {
      resetLocation()
    }

    renderCount.current = renderCount.current + 1
  }, [i18n.language])

  useEffect(() => {
    if (customLatLng) {
      fetchPosition({
        latitude: customLatLng.latitude,
        longitude: customLatLng.longitude,
      })
    }
  }, [customLatLng])

  useEffect(() => {
    if (locationManual && map && userLocation) {
      map.setCenter({ lat: userLocation.latitude, lng: userLocation.latitude })
    }
  }, [locationManual])

  const memoizedMarkerList = useMemo(() => {
    if (!window.google) return [] //TODO: this could result in racing condition (map not loaded yet, but markers are - fix this)
    if (viewRoute) {
      const adjustedPoints = adjustCoordinates(viewRoute.points)
      return adjustedPoints
        .filter((item) => item.title)
        .map((marker: any, idx: number) => {
          return {
            key: idx,
            icon: createMarkerIcon(window, idx, marker.category, map?.getZoom()),
            clickable: !viewRoute.ordered,
            onClick: (e: google.maps.MapMouseEvent) => {
              setViewRoute({ ...viewRoute, points: [marker] })
              setNextStep(true)
            },
            position: {
              lat: marker.coordinates.latitude,
              lng: marker.coordinates.longitude,
            },
            isOpen: false,
          }
        })
    }
    return markers.map((marker, idx) => ({
      key: idx,
      icon: createMarkerIcon(window, idx, marker.category, map?.getZoom()),
      clickable: true,
      onClick: (e: google.maps.MapMouseEvent) => handleMarkerClick(e, marker),
      position: {
        lat: marker.coordinates.latitude,
        lng: marker.coordinates.longitude,
      },
      isOpen: false,
    }))
  }, [markers, currentZoom, viewRoute])

  return (
    <>
      {generatePaymentBlock()}

      <div className={'routes__window' + (active ? ' active' : '')}>
        {viewRoute && generateRoutesWindowBackButton()}
        {generateCloseRoutesWindowButton()}
        <p className="routes__title">{!viewRoute ? t('firstScreen.title') : viewRoute.title}</p>
        {!viewRoute ? (
          <>
            {generateRoutesCategorisHeader()}
            {generateRoutesFilteredList()}
          </>
        ) : (
          <>
            {!nextStep ? (
              <p className={'view__title'}>
                {t('secondScreen.select.p')}
                <span>{t('secondScreen.select.span')}</span>
              </p>
            ) : (
              <p className={'view__title'}>
                {t('secondScreen.unselect.p1')}
                <span>{t('secondScreen.unselect.span')}</span>
                {t('secondScreen.unselect.p2')}
              </p>
            )}
            <div className="view__wrap">
              <GoogleMap
                mapContainerStyle={{
                  width: '100%',
                  height: 'calc(50vh - 100px)',
                  position: 'relative',
                  marginTop: '10px',
                }}
                center={mapCenter}
                zoom={currentZoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onZoomChanged={onZoomChanged}
                onDragEnd={onDragEnd}
                options={mapOptions as any}
              >
                {places.length > 0 && (
                  <MapDirectionsRenderer places={places} travelMode={'WALKING'} />
                )}
                <TransitLayer />
                {memoizedMarkerList.map(({ key, icon, clickable, onClick, position }, index) => (
                  <Marker
                    icon={icon}
                    key={key}
                    clickable={clickable}
                    onClick={onClick}
                    position={position}
                    zIndex={1000 - index}
                  />
                ))}

                <Marker
                  key={80000000}
                  options={circleOptions}
                  icon={createMarkerIcon(
                    window,
                    1,
                    ['user-current-location'],
                    map?.getZoom(),
                    userPosition.heading,
                  )}
                  position={{ lat: userPosition.latitude, lng: userPosition.longitude }}
                />
              </GoogleMap>
              {!nextStep && (
                <div className="routes__item-dotsNames">
                  <div className="routes__item-column">
                    {viewRoute.points
                      .filter((item: any) => item.title)
                      .slice(0, Math.ceil(viewRoute.points.length / 2))
                      .map((point: any, index: number) => (
                        <p className="routes__item-dot-title" key={point.id}>
                          {index !== 0 && <span>•</span>}
                          {index + 1}. {point.title}
                        </p>
                      ))}
                  </div>
                  <div className="routes__item-column">
                    {viewRoute.points
                      .filter((item: any) => item.title)
                      .slice(Math.ceil(viewRoute.points.length / 2))
                      .map((point: any, index: number) => (
                        <p className="routes__item-dot-title" key={point.id}>
                          {index + Math.ceil(viewRoute.points.length / 2) !== 0 && <span>•</span>}
                          {index + Math.ceil(viewRoute.points.length / 2) + 1}. {point.title}
                        </p>
                      ))}
                  </div>
                </div>
              )}
              {nextStep && generateViewRouteButton(viewRoute)}
            </div>
          </>
        )}
      </div>
    </>
  )

  function generatePaymentBlock() {
    return (
      <>
        <div className={`buy_modal ${buyModal ? 'active' : ''}`}>
          {generateRouteCloseButton()}
          <p className={`buy_title`}>Only 3 stories can be played for free</p>
          <p className="buy_text">Purchase full access for €2.99?</p>
          <div className="buttons">
            <button
              onClick={() => {
                setBuyModal(false)
                setThanksModal(true)
              }}
              className={'close'}
            >
              NO WAY!
            </button>
            <button
              onClick={() => {
                setBuyModal(false)
                setThanksModal(true)
              }}
              className={'apply'}
            >
              PURCHASE
            </button>
          </div>
        </div>
        <div className={`thanks_modal ${thanksModal ? 'active' : ''}`}>
          <button
            className="routes__close"
            onClick={() => {
              setThanksModal(false)
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="24"
              viewBox="0 0 34 24"
              fill="none"
            >
              <path
                d="M26.8331 5L7.38867 19"
                stroke="#2B3537"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.38867 5L26.8331 19"
                stroke="#2B3537"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <p className={`buy_title`}>Congratulations! </p>
          <p className="buy_text">
            As one of the first users of our application you get free access for the duration of our
            testing. Please continue using the app and give us some feedback!
          </p>

          <button
            onClick={() => {
              setThanksModal(false)
            }}
            className={'close'}
          >
            OK!
          </button>
        </div>{' '}
      </>
    )
  }

  function generateRouteCloseButton() {
    return (
      <button
        className="routes__close"
        onClick={() => {
          setBuyModal(false)
          setThanksModal(true)
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="24"
          viewBox="0 0 34 24"
          fill="none"
        >
          <path
            d="M26.8331 5L7.38867 19"
            stroke="#2B3537"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.38867 5L26.8331 19"
            stroke="#2B3537"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    )
  }

  function generateViewRouteButton(viewRoute: Excursion) {
    return (
      <button
        className="view__route"
        onClick={() => {
          setViewRoute(null)
          if (localStorage.getItem('trialTours')) {
            if (localStorage.getItem('trialTours') === 'trial') {
              startExcursion(viewRoute.id, viewRoute?.points[0].id)
            }

            // @ts-ignore
            else if (parseInt(localStorage.getItem('trialTours')) !== 3) {
              localStorage.setItem(
                'trialTours',
                // @ts-ignore
                localStorage.getItem('trialTours')
                  ? // @ts-ignore
                    parseInt(localStorage.getItem('trialTours')) + 1
                  : '1',
              )
              startExcursion(viewRoute.id, viewRoute?.points[0].id)
            } else {
              setBuyModal(true)
              onClose()
              localStorage.setItem('trialTours', 'trial')
            }
          } else {
            localStorage.setItem('trialTours', '1')
            startExcursion(viewRoute.id, viewRoute?.points[0].id)
          }
        }}
      >
        {t('secondScreen.start')}
      </button>
    )
  }

  function generateRoutesFilteredList() {
    return (
      <div className="routes">
        {excursions
          .filter((excursion: any) => {
            if (activeCategory === 'all') {
              return true
            } else {
              return excursion.categoryId === activeCategory
            }
          })
          .map((excursion: any) => (
            <div className="routes__item" key={excursion.id}>
              <p className="routes__item-title">{excursion.title}</p>
              <p className="routes__item-time">{excursion.duration}</p>
              <div className="routes__item-dotsNames">
                <div className="routes__item-column">
                  {excursion.points
                    .filter((item: any) => item.title)
                    .slice(0, Math.ceil(excursion.points.length / 2))
                    .map((point: any, index: number) => (
                      <p
                        className="routes__item-dot-title"
                        key={point.id}
                        style={{
                          fontWeight:
                            index === (currentSlideIndices[excursion.id] || 0) ? 'bold' : 'normal',
                        }}
                      >
                        <span className="dot">•</span>
                        <span className="number">{index + 1}.</span> {point.title}
                      </p>
                    ))}
                </div>
                <div className="routes__item-column">
                  {excursion.points
                    .filter((item: any) => item.title)
                    .slice(Math.ceil(excursion.points.length / 2))
                    .map((point: any, index: number) => (
                      <p
                        className="routes__item-dot-title"
                        key={point.id}
                        style={{
                          fontWeight:
                            index + Math.ceil(excursion.points.length / 2) ===
                            (currentSlideIndices[excursion.id] || 0)
                              ? 'bold'
                              : 'normal',
                        }}
                      >
                        <span className="dot">•</span>
                        <span className="number">
                          {index + Math.ceil(excursion.points.length / 2) + 1}.
                        </span>{' '}
                        {point.title}
                      </p>
                    ))}
                </div>
              </div>
              <div className="swiper-container route-swiper">
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  height={261}
                  modules={[Navigation]}
                  navigation={{
                    prevEl: `.swiper-button-prev-${excursion.id}`,
                    nextEl: `.swiper-button-next-${excursion.id}`,
                  }}
                  onActiveIndexChange={(swiper: SwiperType) => {
                    setCurrentSlideIndices((prev) => ({
                      ...prev,
                      [excursion.id]: swiper.activeIndex,
                    }))
                    ReactGA.event({
                      category: 'excursion',
                      action: 'SwipeItem',
                      label: `${excursion.id}_${swiper.activeIndex}`,
                    })
                  }}
                >
                  {excursion.points
                    .filter((item: any) => item.title)
                    .map((point: any) => (
                      <SwiperSlide className={'slide'} key={point.id}>
                        <img className="routes__item-image" src={point.image} alt={point.title} />
                        {/* <p className={'routes__item-descr'}>{point.description}</p> */}
                      </SwiperSlide>
                    ))}
                  <SwiperSlide className={'slide'}>
                    <img className="routes__item-image screen" src={excursion.routeScreen} />
                  </SwiperSlide>
                  <div className={`swiper-button-prev-${excursion.id}`}></div>
                  <div className={`swiper-button-next-${excursion.id}`}></div>
                </Swiper>
              </div>
              <button
                onClick={() => {
                  setViewRoute(excursion)
                  const adjustedPoints = adjustCoordinates(excursion.points)
                  setPlaces([
                    ...adjustedPoints.map((point: { coordinates: any }) => point.coordinates),
                  ])
                  ReactGA.event({
                    category: 'excursion',
                    action: 'ExcursionClick',
                    label: excursion.id,
                  })
                }}
                className="view__route"
              >
                {t('firstScreen.view')}
              </button>
            </div>
          ))}
      </div>
    )
  }

  function generateRoutesCategorisHeader() {
    return (
      <div className={`routes__categories`}>
        {excursionCategories.map((category: ExcursionCategory) => (
          <button
            onClick={() => {
              handleCategoryChange(category.id)
              ReactGA.event({
                category: 'excursion',
                action: 'CategoryClick',
                label: category.name,
              })
            }}
            className={'routes__category' + (activeCategory === category.id ? ' active' : '')}
            key={category.id}
          >
            <p className="routes__category-title">{category.name}</p>
          </button>
        ))}
      </div>
    )
  }

  function generateCloseRoutesWindowButton() {
    return (
      <button
        className="routes__close"
        onClick={() => {
          setViewRoute(null)
          onClose()
          setNextStep(false)
          ReactGA.event({
            category: 'excursion',
            action: 'CloseRoutes',
          })

          navigate('/map', { replace: true })
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="24"
          viewBox="0 0 34 24"
          fill="none"
        >
          <path
            d="M26.8331 5L7.38867 19"
            stroke="#2B3537"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.38867 5L26.8331 19"
            stroke="#2B3537"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    )
  }

  function generateRoutesWindowBackButton() {
    return (
      <button
        className="routes__back"
        onClick={() => {
          setViewRoute(null)
          setNextStep(false)
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.41412 9.99995L10.707 16.2928L9.2928 17.7071L0.585693 8.99995L9.2928 0.292847L10.707 1.70706L4.41412 7.99995H18.9999V9.99995H4.41412Z"
            fill="#2B3537"
          />
        </svg>
      </button>
    )
  }
}

export default RoutesWindow
