import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const FirstTimePopup: React.FC = () => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation('firstTimePopup')
  const popupShownTime = useRef<number>(0)

  const hidePopup = useCallback((event: MouseEvent) => {
    // Ignore clicks that happen too soon after the popup is shown
    const now = Date.now()
    const dt = popupShownTime.current && now - popupShownTime.current
    if (dt < 2000) {
      return
    }
    setShow(false)
    localStorage.setItem('hasSeenFirstTimePopup', 'true')
    document.removeEventListener('click', hidePopup)
  }, [])

  const checkAndShowPopup = useCallback(() => {
    const hasSeenPopup = localStorage.getItem('hasSeenFirstTimePopup')
    const cookiesAccepted = Cookies.get('cookiesAccepted')

    if (!hasSeenPopup && cookiesAccepted && popupShownTime.current == 0) {
      setShow(true)
      popupShownTime.current = Date.now()
      // Add a slight delay before adding the click listener
      setTimeout(() => {
        document.addEventListener('click', hidePopup)
      }, 100)
    }
  }, [hidePopup])

  useEffect(() => {
    // Initial check
    checkAndShowPopup()

    // Set up an interval to keep checking until conditions are met
    const intervalId = setInterval(checkAndShowPopup, 1000)

    // Cleanup function
    return () => {
      clearInterval(intervalId)
      document.removeEventListener('click', hidePopup)
    }
  }, [checkAndShowPopup, hidePopup])

  if (!show) return null

  const message = t(
    'message',
    'Tap objects for audioguides - or choose one of the great routes below',
  )

  return (
    <div className="first-time-popup">
      <p dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  )
}

export default FirstTimePopup
