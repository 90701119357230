import React, { useState } from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'

import { AudioFile } from '@/core/interface/Default'
import { useAppDispatch, useAppSelector } from '@/core/store'
import { addPlayedAudio } from '@/core/store/catalog.store'

interface AudioPlayerProps {
  id: string
  files: AudioFile[]
  type: number //there may be several audios assigned to the same object
  autoPlaying: boolean
  excursion: string | undefined
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ id, files, type, autoPlaying, excursion }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = React.useRef<HTMLAudioElement | null>(null)

  const { playedAudioIds } = useAppSelector((store) => store.catalogStore)
  const dispatch = useAppDispatch()

  const handlePlay = () => {
    Array.from(document.querySelectorAll('audio')).forEach((audio) => {
      if (id !== audio.getAttribute('data-id')) {
        if (!audio.paused) audio.pause()
      }
    })

    {
      ReactGA.event({
        category: 'play',
        action: 'AudioPlayed',
        label: id,
        value: playedAudioIds.length + 1,
      })
    }

    //will trigger GA event by changing the playedAudioIds
    dispatch(addPlayedAudio(id))
  }

  const handlePause = () => {
    if (audioRef.current) {
      setIsPlaying(false)
      if (!audioRef.current.paused) audioRef.current.pause()
    }
    ReactGA.event({
      category: 'play',
      action: 'AudioPaused',
      label: id,
    })
  }

  const handleEnded = () => {
    if (audioRef.current) {
      setIsPlaying(false)
      if (!audioRef.current.paused) audioRef.current.pause()
    }
    ReactGA.event({
      category: 'play',
      action: 'AudioEnded',
      label: id,
    })
  }

  const audioFile = files.find((x) => x.type === type)

  if (!audioFile) {
    return <div>No audio file .</div>
  }

  if (autoPlaying) {
    return (
      <div>
        <audio
          data-id={id}
          preload={'none'}
          ref={audioRef}
          controls={true}
          autoPlay={true}
          controlsList={'nofullscreen nodownload '}
          src={audioFile.url}
          onPause={handlePause}
          onPlay={handlePlay}
          onEnded={handleEnded}
        />
      </div>
    )
  } else {
    return (
      <div>
        <audio
          data-id={id}
          preload={'none'}
          ref={audioRef}
          controls={true}
          controlsList={'nofullscreen nodownload '}
          src={audioFile.url}
          onPause={handlePause}
          onPlay={handlePlay}
          onEnded={handleEnded}
        />
      </div>
    )
  }
}

export default AudioPlayer
