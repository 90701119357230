import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Cookie: React.FC = () => {
  const [showing, setShowing] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(true)

  const { t } = useTranslation('cookie')

  useEffect(() => {
    if (!Cookies.get('cookiesAccepted')) {
      setShowing(true)
    }
  }, [])

  useEffect(() => {
    if (!isFirstRender && !showing) {
      Cookies.set('cookiesAccepted', 'true')
    }

    isFirstRender && setIsFirstRender(false)
  }, [showing])

  const message = t(
    'text',
    'We use cookies to remember the state of the application and to perform anonymous analysis of usage. <br />Please do not use the application if this is unacceptable for you.',
  )

  return (
    <>
      <div className={'cookie' + (showing ? ' active' : '')}>
        <p dangerouslySetInnerHTML={{ __html: message }} />
        <button onClick={() => setShowing(false)}>{t('accept')}</button>
      </div>

      {showing && <div className="cookie-bg" />}
    </>
  )
}

export default Cookie
